// services/api/isJudging/competitions.ts
import { axiosInstance, axiosInstanceMultipart } from '@/utils/axios'

const COMPETITIONS_URL = 'competitions/'

export const CompetitionsService = {

  async getCompetitions() {
    const response = await axiosInstance.get(COMPETITIONS_URL)
    if (response.status !== 200) {
      throw new Error('Failed to fetch competitions')
    }
    return response.data
  },

  async getCompetition(id: string) {
    const response = await axiosInstance.get(COMPETITIONS_URL + id)
    if (response.status !== 200) {
      throw new Error('Failed to fetch competition')
    }
    return response.data
  },

  async createCompetition(formData: any, image: any) {
    try {
      let response = null
      if (formData.is_t3_comp) {
        response = await axiosInstance.post(COMPETITIONS_URL + 'create-with-tunn3l/', 
          {
            auth_token: formData.auth_token,
            url: formData.url,
            t3_competition_token: formData.t3_competition_token
          }
        )
      }
      else {
        response = await axiosInstance.post(COMPETITIONS_URL, formData)
      }

      if (response.status !== 201 || !response.data || !response.data.id || !response.data.name) {
        throw new Error('Invalid response from server')
      }

      if (image) {
        try {
          const competition_id = response.data.id
          const competition_image_response = await axiosInstanceMultipart.post('upload-competition-image/' + competition_id + '/', {
            image: image
          })
          if (competition_image_response.status !== 201 && competition_image_response.status !== 200) {
            console.error('Failed to upload competition image')
          }
        } catch (error) {
          console.error('Error uploading competition image:', error)
          // On ne throw pas l'erreur ici car l'upload de l'image n'est pas critique
        }
      }
      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.name?.[0] || error.response?.data?.message || error.response?.data?.non_field_errors?.[0] || error.message || 'Failed to create competition'
      throw new Error(errorMessage)
    }
  }
}