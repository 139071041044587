import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'
import router from '@/router'

// Function to get CSRF token from cookies
function getCsrfToken(): string | null {
  const name = 'csrftoken'
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
  return cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null
}

export const axiosInstance = axios.create({
  baseURL: '/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const axiosInstanceMultipart = axios.create({
  baseURL: '/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
})

axiosInstanceMultipart.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()
    if (authStore.token) {
      config.headers['Authorization'] = `Bearer ${authStore.token}`
    }

    // Add CSRF token to headers
    const csrfToken = getCsrfToken()
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)


axiosInstance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()
    if (authStore.token) {
      config.headers['Authorization'] = `Bearer ${authStore.token}`
    }

    // Add CSRF token to headers
    const csrfToken = getCsrfToken()
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const authStore = useAuthStore()

    // Check if we have a 401 error and a refresh token
    if (
      error.response?.status === 401 && 
      !originalRequest._retry && 
      authStore.refreshToken
    ) {
      // Prevent infinite loops
      if (originalRequest.url === 'auth/token/refresh/') {
        authStore.eraseLocalTokens()
        router.push({ name: 'login' })
        return Promise.reject(error)
      }

      originalRequest._retry = true
      try {
        const response = await axiosInstance.post('auth/token/refresh/', {
          refresh: authStore.refreshToken
        })

        authStore.setToken(response.data.access)

        axiosInstance.defaults.headers.Authorization = `Bearer ${authStore.token}`
        originalRequest.headers.Authorization = `Bearer ${authStore.token}`

        return axiosInstance(originalRequest)
      } catch (refreshError) {
        authStore.eraseLocalTokens()
        router.push({ name: 'login' })
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error)
  }
)
