// services/api/isJudging/auth.ts
import { axiosInstance } from '@/utils/axios'

const AUTH_URL = 'auth/'

export const AuthService = {
  async signup(email: string, password: string) {
    const response = await axiosInstance.post(AUTH_URL + 'signup/', {
      email: email.toLowerCase(),
      password
    })
    return response
  },

  async verify(uid: string, token: string) {
    const response = await axiosInstance.post(AUTH_URL + 'verify/', { uid, token })
    return response
  },

  async login(email: string, password: string, loginMethod: 'password' | 'email_code') {
    const response = await axiosInstance.post(AUTH_URL + 'login/', {
      username: email.toLowerCase(),
      password,
      login_method: loginMethod
    })
    return response
  },

  async logout(email: string) {
    const response = await axiosInstance.post(AUTH_URL + 'logout/', { email: email })
    return response
  },

  async loginWithCode(email: string, code: string) {
    const response = await axiosInstance.post(AUTH_URL + 'login-with-code/', {
      email: email.toLowerCase(),
      code
    })
    return response
  },

  async requestLoginCode(email: string) {
    const response = await axiosInstance.post(AUTH_URL + 'request-login-code/', {
      email: email.toLowerCase()
    })
    return response
  },

  async verifyEmail(key: string) {
    const response = await axiosInstance.get(AUTH_URL + 'email/verify/' + key)
    return response
  }
}
