<script setup lang="ts">
import {
  CircleUser,
  Home,
  Timer,
  Trophy,
  Cable,
  PanelLeft,
  Unplug,
  Sun,
  Moon,
  Settings,
  MessageCircleMore
} from 'lucide-vue-next'
import { Badge } from '@/components/ui/badge'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'

import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useSpeedRoundStore } from '@/stores/speedRoundStore'
import { useFreeRoundStore } from '@/stores/freeRoundStore'
import { useAppStatusStore } from '@/stores/appStatusStore'
import { useAuthStore } from '@/stores/authStore'
import { useModalStore } from '@/stores/modalStore'

const speedRoundStore = useSpeedRoundStore()
const freeRoundStore = useFreeRoundStore()
const appStatusStore = useAppStatusStore()
const authStore = useAuthStore()
const modalStore = useModalStore()

import { useThemeStore } from '@/stores/themeStore'
const themeStore = useThemeStore()

const { controller, status, isWampConnected, isWampAvailable } = defineProps<{
  controller: string
  status: string
  isWampConnected: boolean
  isWampAvailable: boolean
}>()

const getUserDisplayName = computed(() => {
  const { username, first_name, last_name, primary_email } = authStore.user || {}
  if (first_name && last_name) return `${first_name} ${last_name}`
  if (first_name) return first_name
  if (last_name) return last_name
  return username || ''
})

const router = useRouter()

const handleLogout = async () => {
  try {
    await authStore.logout()
    router.push('/login')
  } catch (error: any) {
    throw error
  }
}

const openUpdateDynamrLocalModal = () => {
  modalStore.openUpdateDynamrLocalModal()
}

const isSheetOpen = ref(false)

const closeSheet = () => {
  isSheetOpen.value = false
}

const controllerDisplay = computed(() => {
  switch (controller) {
    case 'round_speed':
      return 'Speed Round'
    case 'round_free':
      return 'Free Round'
    case 'relative_work_ng':
      return 'FS Round'
    default:
      return controller.charAt(0).toUpperCase() + controller.slice(1)
  }
})

const statusDisplay = computed(() => {
  switch (status) {
    default:
      return status.charAt(0).toUpperCase() + status.slice(1)
  }
})

</script>

<template>
  <header
    class="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6"
  >
    <Sheet v-model:open="isSheetOpen">
      <SheetTrigger as-child>
        <Button size="icon" variant="outline" class="sm:hidden">
          <PanelLeft class="h-5 w-5" />
          <span class="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" class="sm:max-w-xs w-[300px] sm:w-[540px]">
        <nav class="grid gap-6 text-lg font-medium">
          <RouterLink
            to="/"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            @click="closeSheet"
          >
            <Avatar>
              <AvatarImage src="/IJ_logo_lite.png" alt="IsJudging-logo" />
              <AvatarFallback>IJ</AvatarFallback>
            </Avatar>
          </RouterLink>
          <RouterLink
            to="/"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            activeClass="bg-primary text-accent-foreground"
            exactActiveClass="bg-primary text-accent-foreground"
            @click="closeSheet"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <Home class="h-5 w-5" />
            </div>
            Dashboard
          </RouterLink>
          <!-- <RouterLink
            to="/competitions"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            activeClass="bg-primary text-accent-foreground"
            exactActiveClass="bg-primary text-accent-foreground"
            @click="closeSheet"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <Trophy class="h-5 w-5" />
            </div>
            Competitions
          </RouterLink> -->
          <RouterLink
            :to="{ name: 'training' }"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            :class="{
              'bg-primary text-accent-foreground':
                $route.name === 'training' || $route.meta.navActive
            }"
            @click="closeSheet"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <Trophy class="h-5 w-5" />
            </div>
            Training
          </RouterLink>
          <!-- <RouterLink
            to="/status"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            activeClass="bg-primary text-accent-foreground"
            exactActiveClass="bg-primary text-accent-foreground"
            @click="closeSheet"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <Timer class="h-5 w-5" />
            </div>
            Status
          </RouterLink> -->
          <a
            v-if="isWampConnected"
            href="#"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            @click.prevent="openUpdateDynamrLocalModal(); closeSheet();"
          >
            <div class="flex items-center justify-center h-9 w-9 rounded-lg bg-green-500">
              <Cable class="h-5 w-5" />
            </div>
            Connected
          </a>
          <a
            v-else
            href="#"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            @click.prevent="openUpdateDynamrLocalModal(); closeSheet();"
          >
            <div class="flex items-center justify-center h-9 w-9 rounded-lg bg-red-500">
              <Unplug class="h-5 w-5" />
            </div>
            Disconnected
          </a>
          <!-- <RouterLink
            to="/settings"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            activeClass="bg-primary text-accent-foreground"
            exactActiveClass="bg-primary text-accent-foreground"
            @click="closeSheet"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <Settings class="h-5 w-5" />
            </div>
            Settings
          </RouterLink> -->

          <RouterLink
            to="/contact"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
            activeClass="bg-primary text-accent-foreground"
            exactActiveClass="bg-primary text-accent-foreground"
            @click="closeSheet"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <MessageCircleMore class="h-5 w-5" />
            </div>
            Contact
          </RouterLink>

          <a
            href="#"
            @click="themeStore.toggleTheme(); closeSheet();"
            class="flex items-center gap-4 px-2.5 rounded-lg text-muted-foreground"
          >
            <div class="flex items-center justify-center h-9 w-9">
              <Sun class="h-5 w-5" v-if="themeStore.isDark" />
              <Moon class="h-5 w-5" v-else />
            </div>
            <span v-if="themeStore.isDark" class="text-muted-foreground">Light Mode</span>
            <span v-else class="text-muted-foreground">Dark Mode</span>
          </a>
        </nav>
      </SheetContent>
    </Sheet>

    <div class="flex justify-center flex-grow">
      <Badge variant="secondary" v-if="appStatusStore.state.status">
        <div class="flex justify-center gap-x-2">
          <div class="flex items-center">{{ controllerDisplay }}</div>
          <div class="flex items-center">is</div>
          <div class="flex items-center">{{ statusDisplay }}</div>
          <div v-if="status === 'preset'" class="flex items-center">
            {{ appStatusStore.state.data }}
          </div>
          <div
            v-if="
              appStatusStore.state.status === 'blinking' || appStatusStore.state.status === 'frozen'
            "
            v-for="(color, key) in appStatusStore.getColorsBackground"
            :key="key"
            class="w-6 h-6 border"
            :style="{ backgroundColor: `rgb(${color})` }"
          ></div>
          <div
            v-if="appStatusStore.state.status === 'animated'"
            class="w-12 h-6 border bg-rainbow"
          ></div>
        </div>
        <!-- <div v-if="speedRoundStore.status" class="flex justify-center gap-x-4">
          <div>
            {{ speedRoundStore.stopwatch }}
          </div>
          <div v-if="speedRoundStore.current_bust_count">
            <span> Busts : {{ speedRoundStore.current_bust_count }}</span>
          </div>
          <div v-if="speedRoundStore.current_skip_count">
            <span> Skips : {{ speedRoundStore.current_skip_count }}</span>
          </div>
        </div>
        <div v-if="freeRoundStore.status">
          <button>
            {{ freeRoundStore.stopwatch }}
          </button>
        </div> -->
      </Badge>
    </div>

    <div class="user-info">
      <RouterLink v-if="authStore.token" :to="{ name: 'my-account' }">
        {{ getUserDisplayName }}
      </RouterLink>
    </div>

    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <Button variant="secondary" size="icon" class="rounded-full">
          <CircleUser class="h-5 w-5" />
          <span class="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <RouterLink v-if="authStore.token" to="/my-account">
          <DropdownMenuItem>My Account</DropdownMenuItem>
        </RouterLink>
        <RouterLink v-if="!authStore.token" to="/login">
          <DropdownMenuItem>Login</DropdownMenuItem>
        </RouterLink>
        <RouterLink v-if="!authStore.token" to="/signup">
          <DropdownMenuItem>Sign Up</DropdownMenuItem>
        </RouterLink>
        <DropdownMenuSeparator v-if="authStore.token" />
        <DropdownMenuItem v-if="authStore.token" @click="handleLogout">Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </header>
</template>
